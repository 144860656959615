import React, { useState, useCallback, useEffect, useRef } from 'react'
import './CharacterSelection.css'
import infoIcon from 'images/icons/info_button.png'
import { sounds } from 'utils/sounds'
import clsx from 'clsx'
import InformationSection from 'components/InformationSection/InformationSection'
import { characters } from 'constants/characters'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ArrowButton = React.memo(({ direction, onClick, isDisabled, isAllCharactersViewed }) => {
    return (
        <button
            type="button"
            className={clsx(`action-${direction}-arrow`, { scaled: !isAllCharactersViewed && direction === 'right' })}
            onClick={onClick}
            disabled={isDisabled}
        />
    )
})

const SelectButton = React.memo(({ onClick, isDisabled }) => {
    return (
        <button type="button" className="btn-select" onClick={onClick} disabled={isDisabled}>
            Select
        </button>
    )
})

const CharacterSelection = ({ toggleShowBackdrop, handleSelectClick, step, handleNextStep, hidden, toggleTutorVisible }) => {
    const [selectedCharacter, setSelectedCharacter] = useState(0)
    const [viewedCharacters, setViewedCharacters] = useState(new Set())
    const [showPopup, setShowPopup] = useState(false)
    const [popupContent, setPopupContent] = useState('')
    const [hasViewedAllCharacters, setHasViewedAllCharacters] = useState(false)
    const [popupButtonText, setPopupButtonText] = useState('')

    const sliderRef = useRef()

    useEffect(() => {
        setHasViewedAllCharacters(viewedCharacters.size === characters.length)
    }, [viewedCharacters])

    useEffect(() => {
        if (hasViewedAllCharacters && step === 5) handleNextStep()
    }, [handleNextStep, hasViewedAllCharacters, step])

    useEffect(() => {
        if (step > 5) setHasViewedAllCharacters(true)
    }, [step])

    const handleInfoClick = useCallback(() => {
        setPopupContent(
            <div className="popup-text-content">
                <p className="popup_title_main">Pick Your Unique POOMB PET and Start Earning $COGA Tokens!</p>
                <p className="popup_title_secondary">
                    Choose wisely and begin your journey in the Poomb Creatures ecosystem—where fun meets rewards.
                </p>
                <p className="popup_title_secondary">Start farming and explore a world of endless possibilities!</p>
                <p className="popup_title_secondary">Joy-enjoy, Poomb-Poomb!</p>
            </div>,
        )
        setPopupButtonText("LET'S GO")
        setShowPopup(true)
        if (toggleTutorVisible) {
            toggleTutorVisible()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClosePopup = useCallback(() => {
        setShowPopup(false)
        if (toggleTutorVisible) {
            toggleTutorVisible()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNextSlide = useCallback(() => {
        if (step === 5 || step === 6) {
            toggleShowBackdrop()
        }
        if (step === 6) {
            handleNextStep()
        }
        setViewedCharacters(prevViewed => new Set(prevViewed).add(selectedCharacter))
        sliderRef.current.slickNext()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, toggleShowBackdrop, selectedCharacter])

    const handlePrevSlide = useCallback(() => {
        if (step === 6) {
            handleNextStep()
        }
        sliderRef.current.slickPrev()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        beforeChange: (_, next) => {
            sounds.change.play()
            setSelectedCharacter(next)
        },
    }

    return (
        <div className={clsx('page', { 'character-selection-wrapper--hidden': hidden })}>
            <div className="container">
                <div className={clsx('character-selection-wrapper')}>
                    <div className="info-left-icon" onClick={handleInfoClick}>
                        <img src={infoIcon} alt="Info Icon" className="info-icon" />
                    </div>

                    <div className="select-character-wrapper">
                        <Slider ref={sliderRef} {...settings} arrows={false}>
                            {characters.map((character, index) => (
                                <div key={character.name} className={clsx('pet-item')}>
                                    <div className="character_info">
                                        <div className="character-label" title={character.displayName}>
                                            {character.displayName}
                                        </div>
                                        <p className="character_description">{character.description}</p>
                                    </div>
                                    <img src={character.imageSrc} alt={character.displayName} className="character-image" />
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="action-select-wrapper">
                        <ArrowButton direction="left" onClick={handlePrevSlide} isDisabled={!hasViewedAllCharacters && step === 5} />
                        <SelectButton
                            onClick={() => handleSelectClick(characters[selectedCharacter].name)}
                            isDisabled={!hasViewedAllCharacters && step === 5}
                        />
                        <ArrowButton direction="right" onClick={handleNextSlide} isAllCharactersViewed={hasViewedAllCharacters} />
                    </div>
                </div>
            </div>

            {showPopup && <InformationSection onClose={handleClosePopup} content={popupContent} buttonText={popupButtonText} />}
        </div>
    )
}

export default CharacterSelection
