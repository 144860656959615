const units = [
	{ value: 1e33, symbol: "Dc" },
	{ value: 1e30, symbol: "N" },
	{ value: 1e27, symbol: "Oc" },
	{ value: 1e24, symbol: "Sp" },
	{ value: 1e15, symbol: "Qi" },
	{ value: 1e12, symbol: "B" },
	{ value: 1e9, symbol: "M" },
	{ value: 1e6, symbol: "K" },
	{ value: 1e3, symbol: "" },
];

export class CoinsService {
	constructor(coins) {
		this.coins = coins;
	}

	getCoins() {
		return this.coins;
	}

	#formatCoinsShort(value) {
		if (value >= 1e6) {
			return `${(value / 1e6).toFixed(1).replace(/\.0$/, "")}m`;
		}
		if (value >= 1e3) {
			return `${(value / 1e3).toFixed(1).replace(/\.0$/, "")}k`;
		}
		return value.toString();
	}

	#formatCoins(value) {
		const unit = units.find((unit) => value >= unit.value);

		if (unit) {
			const formattedValue = (value / unit.value).toFixed(3).replace(".", ",");
			return formattedValue + unit.symbol;
		}

		return Math.floor(value).toLocaleString("de-DE");
	}

	getFormatedCoins() {
		return this.#formatCoins(this.coins);
	}

	getFormatedCoinsShort() {
		return this.#formatCoinsShort(this.coins);
	}
}
