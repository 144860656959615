import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import banner from "images/shop/banner.png";
import ellipseInactive from "images/shop/slider/ellipse-50.png";
import ellipseActive from "images/shop/slider/ellipse-100.png";

import "swiper/css";

const slides = [
	{
		id: 1,
		title: "Coming soon",
		image: banner,
	},
	{
		id: 2,
		title: "Coming soon",
		image: banner,
	},
	{
		id: 3,
		title: "Coming soon",
		image: banner,
	},
];

const Slider = () => {
	const pagination = {
		clickable: true,
		renderBullet: (_, className) => {
			const isActive = className.includes("swiper-pagination-bullet-active");

			return `
            <button
                type="button"
                class="shop-slider-button ${className}"
            >
                <img
                    src="${isActive ? ellipseActive : ellipseInactive}"
                    alt="slider-button"
                />
            </button>
        `;
		},
	};

	return (
		<Swiper
			className="shop-slider"
			slidesPerView={1}
			spaceBetween={20}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			modules={[Autoplay, Pagination]}
			pagination={pagination}
		>
			{slides.map((slide) => (
				<SwiperSlide key={slide.id}>
					<div
						className="shop-slider-slide"
						style={{ backgroundImage: `url(${slide.image})` }}
					>
						<div className="shop-slider-slide-overlay">
							<span className="coming-soon-text">{slide.title}</span>
						</div>
					</div>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default Slider;
