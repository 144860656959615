import { ShopType } from "constants/shop";
import { useShopPrice } from "context/shop-price";
import cogaBanner from "images/shop/coga-banner.png";
import energyBanner from "images/shop/slider/energy.png";

import Bullet from "./Bullet";
import ShopHeader from "./Header";
import Slider from "./Slider";

import "swiper/css";
import "./Shop.css";

const Shop = ({ telegramId }) => {
	const { cogaPointsPrice, energyPrice, isLoading } = useShopPrice();

	if (isLoading) return null;

	return (
		<div className="shop-page">
			<div className="shop-scrollable-container">
				<ShopHeader />
				<div className="shop-slider">
					<Slider />
				</div>
				<img className="info-banner" src={energyBanner} alt="Energy" />
				<div className="shop-bullets">
					{energyPrice.map((item) => (
						<Bullet
							telegramId={telegramId}
							key={item.price}
							type={ShopType.ENERGY}
							count={item.reward}
							price={item.price}
							discount={item.discount}
						/>
					))}
				</div>
				<img className="info-banner" src={cogaBanner} alt="COGA" />
				<div className="shop-bullets">
					{cogaPointsPrice.map((item) => (
						<Bullet
							telegramId={telegramId}
							key={item.price}
							type={ShopType.COGAS}
							count={item.reward}
							price={item.price}
							discount={item.discount}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Shop;
