import { memo, useEffect } from 'react'
import { calculateStorageTop, sounds, formatValue } from 'utils'
// import fullIcon from 'images/icons/capi-storage.png'

import './Storage.css'
import clsx from 'clsx'

const Storage = ({ player, sendMessage, storageTokens, setStorageTokens, handleFlyCoins, playerUpgrades, step, storageUpdTime }) => {
    useEffect(() => {
        if (step || !storageUpdTime) return

        const checkStorageUpdate = () => {
            const currentTime = new Date().getTime() / 1000

            if (currentTime >= storageUpdTime) {
                sendMessage(
                    JSON.stringify({
                        operation: 'read_player_soft_tokens',
                        data: {
                            telegram_id: player?.telegram_id,
                        },
                    }),
                )

                clearInterval(interval)
            }
        }

        const interval = setInterval(checkStorageUpdate, 1000)
        return () => clearInterval(interval)
    }, [storageUpdTime, player?.telegram_id, sendMessage, step])

    const handleStorageClick = async () => {
        if (storageTokens > 0) {
            sounds.reward.play()

            const targetElement = document.querySelector('.coins-wrapper h1')
            handleFlyCoins(targetElement)

            const tokenDecrement = storageTokens / 30

            const animateStorageReduction = () => {
                setStorageTokens(prevTokens => {
                    const newTokens = prevTokens - tokenDecrement
                    if (newTokens <= 0) {
                        return 0
                    } else {
                        requestAnimationFrame(animateStorageReduction)
                        return newTokens
                    }
                })
            }

            requestAnimationFrame(animateStorageReduction)

            const requestData = {
                operation: 'convert_soft_tokens',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }
    }

    return (
        <div
            className={clsx('progress-table-wrapper', { tutorial_active: step === 12 || step === 13 || step === 14 }, { non_active: step })}
            onClick={handleStorageClick}
        >
            <div className="progress-table">
                {/* <div className="fixed-storage-img"> */}
                {/* <div
                        className="storage-background"
                        style={{
                            top: `${
                                playerUpgrades?.length > 0
                                    ? calculateStorageTop(
                                          storageTokens,
                                          playerUpgrades.find(upgrade => upgrade.upgrade_type === 'storage_power')?.current_power,
                                      )
                                    : step
                                    ? 0
                                    : 100
                            }%`,
                        }}
                    /> */}
                {/* </div> */}
                {/* <img src={fullIcon} alt="Storage" className="progress-table-img" onClick={handleStorageClick} /> */}

                <div
                    className="fullJarWrapper"
                    style={{
                        height: `${
                            playerUpgrades?.length > 0
                                ? calculateStorageTop(
                                      storageTokens,
                                      playerUpgrades.find(upgrade => upgrade.upgrade_type === 'storage_power')?.current_power,
                                  )
                                : step
                                ? 100
                                : 0
                        }%`,
                    }}
                />
            </div>
            <h3 className="storage-text" title={storageTokens > 0 ? formatValue(storageTokens) : 0}>
                {storageTokens > 0 ? formatValue(storageTokens) : 0}
            </h3>
        </div>
    )
}

export default memo(Storage)
