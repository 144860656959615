import React, { useState } from 'react'
import './FormStep.css'
import clsx from 'clsx'
import { sendNickname, addCharacterToPlayer } from 'api/playersApi'
import { sounds } from '../../../utils'
import Leaf from 'images/leaf_ui_nickname_form.png'

const FormStep = ({ /* selectCharacter, */ userId, /* selectedCharacter = 'main', */ handleNextStep }) => {
    const [nickname, setNickname] = useState('')
    const [error, setError] = useState('')
    const [sendError, setSendError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleInputChange = e => {
        if (sendError) {
            setSendError('')
        }
        const value = e.target.value.trim()
        setNickname(value)

        if (/[^a-zA-Z\d_]/.test(value)) {
            setError('Nickname can contain only English letters, numbers, and underscores.')
        } else if (value.length > 20) {
            setError('Nickname must be no more than 20 characters.')
        } else if (value.length < 3) {
            setError('Nickname must be at least 3 characters long.')
        } else {
            setError('')
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        sounds.reward.play()

        if (error || !nickname) return

        // sessionStorage.clear()
        // localStorage.clear()

        setLoading(true)
        try {
            await sendNickname(userId, nickname)
            handleNextStep()
            // await addCharacterToPlayer(userId, selectedCharacter)
            // selectCharacter(selectedCharacter)
        } catch (error) {
            setSendError('Failed to send nickname. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="form_wrapper">
            <div className="image_form_container">
                <img src={Leaf} alt="Leaf" className="level_leaf_image" />
                <form className={clsx('form_nickname')} onSubmit={handleSubmit}>
                    <label>
                        <span className="label_text">Enter your nick name</span>
                        <input type="text" value={nickname} onChange={handleInputChange} disabled={loading} />
                        {error && <p className="error_message">{error}</p>}
                        {sendError && <p className="error_message">{sendError}</p>}
                    </label>

                    <button
                        className={clsx('achieve-action-btn', 'submit_button', 'achieve-green-action-btn', {
                            'submit_button--loading': loading,
                        })}
                        type="submit"
                        disabled={!!error || !nickname || loading}
                    >
                        {loading ? 'Loading...' : 'OK'}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default FormStep
