import React, { useState, useEffect } from 'react'
import './Loader.css'
import logo from 'images/logo.png'

import CapybaraIdle from 'assets/gif_characters/Capybara_Idle-min.gif'
import CapybaraTap from 'assets/gif_characters/Capybara_Tap-min.gif'
import MonkeyIdle from 'assets/gif_characters/Monkey_Idle-min.gif'
import MonkeyTap from 'assets/gif_characters/Monkey_Tap-min.gif'
import PeafowlIdle from 'assets/gif_characters/Peafowl_Idle-min.gif'
import PeafowlTap from 'assets/gif_characters/Peafowl_Tap-min.gif'
import TarsiusIdle from 'assets/gif_characters/Tarsius_Idle-min.gif'
import TarsiusTap from 'assets/gif_characters/Tarsius_Tap-min.gif'
import TigerIdle from 'assets/gif_characters/Tiger_Idle-min.gif'
import TigerTap from 'assets/gif_characters/Tiger_Tap-min.gif'

import GamePlayBackground from 'images/gameplay-bg.jpg'
import ShopBackground from 'images/shop.jpg'
import BlueButton from 'images/buttons/blue-btn.png'
import Blue from 'images/buttons/blue.png'
import GreenButton from 'images/buttons/green-btn.png'
import Green from 'images/buttons/green.png'
import AnimalFrame from 'images/icons/animal-frame.png'
import Autotap from 'images/icons/autotap.png'
import CapiStorage from 'images/icons/capi-storage.png'
import Close from 'images/icons/close.png'
import Coin from 'images/icons/coin.png'
import GogaCoin from 'images/icons/coga_token.png'
import Full from 'images/icons/full.png'
import InfoIcon from 'images/icons/info_button.png'
import LeftArrow from 'images/icons/left-arrow.png'
import LeftMenuDisable from 'images/icons/level_up_disabled.png'
import LeftMenu from 'images/icons/level_up.png'
import Plus from 'images/icons/invite.png'
import Rectangle from 'images/icons/rank.png'
import RightArrow from 'images/icons/right-arrow.png'
import RightMenuDisable from 'images/icons/calendar_disabled.png'
import RightMenu from 'images/icons/calendar.png'
import Bar from 'images/bar.png'
import KapibaraIcon from 'images/kapibara-icon.png'
import LightBar from 'images/light-bar.png'
import Logo from 'images/logo.png'
import MenuBg from 'images/menu.png'
import MonkeyIcon from 'images/monkey-icon.png'
import PeacockIcon from 'images/peacock-icon.png'
import PopupLeafLong from 'images/leaf.png'
import PopupLeafSmall from 'images/leaf_cut.png'
import ProgressBar from 'images/progressbar.svg'
import TarsiusIcon from 'images/tarsius-icon.png'
import TigerIcon from 'images/tiger-icon.png'
import LeafFrame from 'images/leaf-frame-popup.webp'
import Robot from 'assets/robot.webp'

import { sounds } from 'utils/sounds'
import AchieveMenu from 'components/AchieveMenu/AchieveMenu'
import InformationSection from 'components/InformationSection/InformationSection'
import CharacterSelection from 'components/CharacterSelection/CharacterSelection'

const imageUrls = [
    GamePlayBackground,
    ShopBackground,
    BlueButton,
    Blue,
    GreenButton,
    Green,
    AnimalFrame,
    Autotap,
    CapiStorage,
    Close,
    Coin,
    GogaCoin,
    Full,
    InfoIcon,
    LeftArrow,
    LeftMenuDisable,
    LeftMenu,
    Plus,
    Rectangle,
    RightArrow,
    RightMenuDisable,
    RightMenu,
    Bar,
    KapibaraIcon,
    LightBar,
    Logo,
    MenuBg,
    MonkeyIcon,
    PeacockIcon,
    PopupLeafLong,
    PopupLeafSmall,
    ProgressBar,
    TarsiusIcon,
    TigerIcon,
    LeafFrame,
    Robot,
]

const allGifImages = {
    main: [TigerIdle, TigerTap],
    pet1: [TarsiusIdle, TarsiusTap],
    pet2: [PeafowlIdle, PeafowlTap],
    pet3: [CapybaraIdle, CapybaraTap],
    pet4: [MonkeyIdle, MonkeyTap],
}

const loadImage = src => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => resolve(src)
        img.onerror = err => {
            console.error(`Error loading image: ${src}`, err)
            reject(err)
        }
    })
}

function Loader({ setLoadingComplete, player }) {
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(0)
    const [dotsCount, setDotsCount] = useState(1)

    useEffect(() => {
        const dotsInterval = setInterval(() => {
            setDotsCount(prevDots => (prevDots === 3 ? 1 : prevDots + 1))
        }, 500)

        return () => clearInterval(dotsInterval)
    }, [])

    useEffect(() => {
        const loadAllResources = async () => {
            let gifImages = []
            if (player.character && player.character.name) {
                gifImages = allGifImages[player.character.name] || []
            } else {
                gifImages = Object.values(allGifImages).flat()
            }

            const allImagesToLoad = [...imageUrls /* , ...gifImages */]
            const totalResources = allImagesToLoad.length + Object.keys(sounds).length
            let loadedResources = 0

            const updateProgress = () => {
                loadedResources += 1
                const newProgress = (loadedResources / totalResources) * 100
                setProgress(Math.round(newProgress))
            }

            try {
                const imagePromises = allImagesToLoad.map(url =>
                    loadImage(url)
                        .then(updateProgress)
                        .catch(err => {
                            console.error(`Error loading image: ${url}`, err)
                            updateProgress()
                        }),
                )

                const soundPromises = Object.values(sounds).map(
                    soundPool =>
                        new Promise(resolve => {
                            soundPool.preload()
                            updateProgress()
                            resolve()
                        }),
                )

                await Promise.all([...imagePromises, ...soundPromises])

                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            } catch (error) {
                console.error('Error loading resources:', error)
            }
        }

        loadAllResources()
    }, [player])

    useEffect(() => {
        if (!loading) {
            setLoadingComplete()
        }
    }, [loading, setLoadingComplete])

    if (loading) {
        return (
            <div className="page">
                <div className="container">
                    <div className="loader-wrapper">
                        <img src={logo} className="logo" alt="Logo" />
                        <div>
                            <div className="loading-text-wrapper">
                                <div>
                                    <span className="loading-text" title="Loading">
                                        Loading
                                    </span>
                                    <div className="dots-wrapper">
                                        <span className="dots"> {'.'.repeat(dotsCount)}</span>
                                        <span className="dots-stroke"> {'.'.repeat(dotsCount)}</span>
                                    </div>
                                </div>

                                <div className="progress-value-wrapper">
                                    <span className="progress-value">{progress}%</span>
                                    <span className="progress-value-stroke">{progress}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AchieveMenu hidden />
                <InformationSection hidden />
                {!player.character && <CharacterSelection hidden />}
            </div>
        )
    }

    return null
}

export default Loader
