import React from 'react'
import './InformationSection.css'
import Leaf from 'images/leaf_ui_nickname_form.png'
import clsx from 'clsx'

const InformationSection = ({ onClose, content, buttonText, children, hidden }) => {
    const handleContentClick = e => {
        e.stopPropagation()
    }

    return (
        <div className={clsx('popup-overlay', { 'popup-overlay--hidden': hidden })} onClick={onClose}>
            <div className="popup-content" onClick={handleContentClick}>
                <img src={Leaf} alt="Leaf" className="level_leaf_image" />

                {/* Dynamic content */}
                <div className="popup-info">
                    <div className="close-btn-popup" onClick={onClose}>
                        <img src={require('images/icons/close.png')} alt="Close" className="close-icon" />
                    </div>
                    <div className="dynamic-content">{content ? content : children}</div>
                </div>

                {/* Main action button */}
                <button className="btn-green" onClick={onClose}>
                    {buttonText}
                </button>
            </div>
        </div>
    )
}

export default InformationSection
