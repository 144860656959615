import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { getCogaPointsPrice, getEnergyPrice } from "../api/pricesApi";

export const ShopPriceContext = createContext(null);

export const ShopPriceProvider = ({ children }) => {
	const [energyPrice, setEnergyPrice] = useState(null);
	const [cogaPointsPrice, setCogaPointsPrice] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		Promise.all([getEnergyPrice(), getCogaPointsPrice()])
			.then(([energyPriceData, cogaPointsPriceData]) => {
				setEnergyPrice(
					Object.entries(energyPriceData).map(([key, value]) => ({
						price: Number(key),
						reward: value,
					})),
				);
				setCogaPointsPrice(
					Object.entries(cogaPointsPriceData).map(([key, value]) => ({
						price: Number(key),
						reward: value,
					})),
				);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching prices:", error);
			});
	}, []);

	const value = useMemo(
		() => ({ energyPrice, cogaPointsPrice, isLoading }),
		[energyPrice, cogaPointsPrice, isLoading],
	);

	return (
		<ShopPriceContext.Provider value={value}>
			{children}
		</ShopPriceContext.Provider>
	);
};

export const useShopPrice = () => {
	const context = useContext(ShopPriceContext);

	if (!context) {
		throw new Error("useShopPrice must be used within a PriceProvider");
	}

	return context;
};
