import { createContext, useContext, useMemo } from "react";

export const CogaCoinsContext = createContext(null);

export const CogaCoinsProvider = ({
	children,
	coins,
	setCoins,
}) => {
	const value = useMemo(
		() => ({
			coins,
			setCoins,
		}),
		[coins, setCoins],
	);

	return (
		<CogaCoinsContext.Provider value={value}>{children}</CogaCoinsContext.Provider>
	);
};

export const useCogaCoins = () => {
	const context = useContext(CogaCoinsContext);

	if (!context) {
		throw new Error("useCogaCoins must be used within a CogaCoinsProvider");
	}

	return context;
};
