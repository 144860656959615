import React from 'react'
import './LvlProgress.css'

const LvlProgress = ({ levelProgress, tutorial }) => {
    const progressPercentage = tutorial
        ? 45
        : levelProgress.player_total_clicks && levelProgress.clicks_to_next_lvl > 0
        ? Math.min(
              Math.max(
                  Math.floor(
                      ((levelProgress.player_total_clicks - levelProgress.clicks_needed_for_current_lvl) /
                          (levelProgress.clicks_to_next_lvl - levelProgress.clicks_needed_for_current_lvl)) *
                          100,
                  ),
                  0,
              ),
              100,
          )
        : levelProgress && levelProgress.player_total_clicks && !levelProgress.clicks_to_next_lvl
        ? 100
        : 0

    return (
        <div className="lvl_progress_container">
            <div className="lvl_progress_container_indicator" style={{ width: `${progressPercentage}%` }} />
            <div className="lvl_progress_value_wrapper">
                <p>{progressPercentage} %</p>
            </div>
        </div>
    )
}

export default LvlProgress
