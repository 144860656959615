import { createContext, useContext, useMemo } from "react";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, sendMessage, lastMessage }) => {
	const value = useMemo(
		() => ({
			sendMessage,
			lastMessage,
		}),
		[sendMessage, lastMessage],
	);

	return (
		<WebSocketContext.Provider value={value}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocketMessage = () => {
	const context = useContext(WebSocketContext);

	if (!context) {
		throw new Error("useWebSocketMessage must be used within a WebsocketProvider");
	}

	return context;
};
