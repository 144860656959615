import { createContext, useContext, useMemo } from "react";

export const EnergyContext = createContext(null);

export const EnergyProvider = ({
	children,
	initialEnergy,
	energy,
	setEnergy,
	maxEnergyValue,
}) => {
	const value = useMemo(
		() => ({
			initialEnergy,
			energy,
			setEnergy,
			maxEnergyValue,
		}),
		[initialEnergy, energy, setEnergy, maxEnergyValue],
	);

	return (
		<EnergyContext.Provider value={value}>{children}</EnergyContext.Provider>
	);
};

export const useEnergy = () => {
	const context = useContext(EnergyContext);

	if (!context) {
		throw new Error("useEnergy must be used within a EnergyProvider");
	}

	return context;
};
