export const characterImages = Object.freeze({
    main: {
        idle: require('../assets/gif_characters/Tiger_Idle-min.gif'),
        animate: require('../assets/gif_characters/Tiger_Tap-min.gif'),
    },
    pet1: {
        animate: require('../assets/gif_characters/Tarsius_Tap-min.gif'),
        idle: require('../assets/gif_characters/Tarsius_Idle-min.gif'),
    },
    pet2: {
        idle: require('../assets/gif_characters/Peafowl_Idle-min.gif'),
        animate: require('../assets/gif_characters/Peafowl_Tap-min.gif'),
    },
    pet3: {
        idle: require('../assets/gif_characters/Capybara_Idle-min.gif'),
        animate: require('../assets/gif_characters/Capybara_Tap-min.gif'),
    },
    pet4: {
        idle: require('../assets/gif_characters/Monkey_Idle-min.gif'),
        animate: require('../assets/gif_characters/Monkey_Tap-min.gif'),
    },
})
