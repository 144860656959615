import React, { useEffect, useState } from 'react'
import coin from 'images/icons/coin.png'
import './RewardCoinAnimation.css'

const RewardCoinAnimation = ({ trigger, startX, startY, onComplete }) => {
    const [isAnimating, setIsAnimating] = useState(false)

    useEffect(() => {
        if (trigger) {
            setIsAnimating(true)

            const timeout = setTimeout(() => {
                setIsAnimating(false)
                if (onComplete) {
                    onComplete()
                }
            }, 1500)

            return () => clearTimeout(timeout)
        }
    }, [trigger, onComplete])

    return (
        <>
            {isAnimating && (
                <div className="reward-coin-animation" style={{ top: `${startY}px`, left: `${startX}px` }}>
                    <img src={coin} alt="Coin" className="coin-icon-rewards" width={20} />
                </div>
            )}
        </>
    )
}

export default RewardCoinAnimation
