// export const calculateStorageTop = (storageTokens, maxStorage) => {
//     const fillPercentage = Math.min((storageTokens / maxStorage) * 100, 100)
//     return 88 - (fillPercentage * 78) / 100
// }

// Calculation fill storage for jar
export const calculateStorageTop = (storageTokens, maxStorage) => {
    const fillPercentage = Math.min((storageTokens / maxStorage) * 100, 100)

    if (fillPercentage === 100) {
        return 100
    }
    if (fillPercentage >= 70 && fillPercentage < 100) {
        return 70
    }
    if (fillPercentage < 70 && fillPercentage + 4 > 70) {
        return 70
    }
    return fillPercentage + 4
}
