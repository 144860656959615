import { useCogaCoins } from "context";
import { CoinsService } from "services";

import cogaCoinImg from "images/shop/currency/coga_point_icon.png";

const CogaInfo = () => {
	const { coins } = useCogaCoins();
	const service = new CoinsService(coins);

	return (
		<div className="shop-header-info-element-container">
			<div className="shop-header-info-element-inner-container">
				<img
					className="shop-header-info-element-coin-image"
					src={cogaCoinImg}
					alt="Coga coin"
				/>
				<span className="shop-header-info-element-text shop-header-info-element-coga-count">
					{service.getFormatedCoins()}
				</span>
			</div>
		</div>
	);
};

export default CogaInfo;
