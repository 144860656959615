const upgradesTestArray = [
    {
        upgrade_type: 'tap_power',
        level: 3,
        current_power: 1,
        next_power: 84,
        next_level_cost: 6,
    },
    {
        upgrade_type: 'auto_tap_power',
        level: 8,
        current_power: 1,
        next_power: 5,
        next_level_cost: 50,
    },
    {
        upgrade_type: 'storage_power',
        level: 2,
        current_power: 25000,
        next_power: 30000,
        next_level_cost: 200,
    },
]

export default upgradesTestArray
