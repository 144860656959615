export const formatValueForButtons = value => {
    if (value >= 1_000_000_000_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000_000_000_000).toFixed(3).replace('.', ',') + 'Dc'
    } else if (value >= 1_000_000_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000_000_000).toFixed(3).replace('.', ',') + 'N'
    } else if (value >= 1_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000).toFixed(3).replace('.', ',') + 'Oc'
    } else if (value >= 1_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000).toFixed(3).replace('.', ',') + 'Sp'
    } else if (value >= 1_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000).toFixed(3).replace('.', ',') + 'Qi'
    } else if (value >= 1_000_000_000_000) {
        return (value / 1_000_000_000_000).toFixed(3).replace('.', ',') + 'B'
    } else if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(3).replace('.', ',') + 'M'
    } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(3).replace('.', ',') + 'K'
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(value % 1000 === 0 ? 0 : 1).replace('.', ',') + 'K'
    }
    return Math.floor(value).toLocaleString('de-DE')
}
