import { createContext, useContext, useMemo, useState } from "react";

export const ShopContext = createContext(null);

export const ShopProvider = ({ children }) => {
	const [open, setOpen] = useState(false);

	const value = useMemo(
		() => ({
			open,
			setOpen,
		}),
		[open],
	);

	return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

export const useShop = () => {
	const context = useContext(ShopContext);

	if (!context) {
		throw new Error("useShop must be used within a ShopProvider");
	}

	return context;
};
