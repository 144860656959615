const tutorialStepsText = Object.freeze({
    step1: 'To start\nplaying, first\nsubscribe.',
    step2: '----Welcome to\nthe 2 step!',
    // step2: "Now let's\nplay and earn!",
    step3: 'Great! Now set your nickname\nand start earning!',
    step4: '----Welcome to\nthe 4 step!',
    step5: 'At first, choose your friend.',
    step6: 'Now it’s time to choose your companion!',
    step7: 'Now it’s time to choose your companion!',
    step8: 'Start earning\nby tapping on the pet',
    step9: '----Welcome to\nthe 9 step!',
    step10: "Energy for pet's tap",
    step11: 'Your COGA points that you can\nexchange for real money',
    step12: 'There is also a storage\nlimit, which can be increased as well.\n\nThe goal of the storage system\nis to increase the frequency of player logins\nso they can collect coins from their storage.',
    step13: 'From the moment you enter the game,\ncoins are automatically credited\nto your storage (offline tapper).\n\nThe system works on the principl\nof 100 coins per minute, and the coin\naccumulation rate per second can be upgraded.',
    step14: 'The time it takes to fill the\nstorage depends on two factors:\n\n1. The rate of coins per minute\n(which can be upgraded)\n\n2. The storage size (which can be upgraded)\n\nTo collect the coins, simply log in\nand tap on the storage.',
    step15: 'Your level and extra bonuses',
    step16: 'Invite friends.\nRank up.\nEarn even more.',
    step17: '----Welcome to\nthe 17 step!',
    step18: '----Welcome to\nthe 18 step!',
    step19: 'Upgrade your account\nto be the coolest and richest!',
    step20: '----Welcome to\nthe 20 step!',
    step21: '----Welcome to\nthe 21 step!',
    step22: "Don't forget\nabout tasks that\nwill give you a turbo boost.",
    step23: '----Welcome to\nthe 23 step!',
})

export default tutorialStepsText
