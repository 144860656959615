import { upgradePlayerBuffs } from 'api/playersApi'
import JarFull from 'assets//gold_jar2_full.webp'
import CoinsImg from 'assets/coins.webp'
import clsx from 'clsx'
import IconBackground from 'components/IconBackground/IconBackground'
import RightMenuIcon from 'images/icons/calendar.png'
import LeftMenuIconDisabled from 'images/icons/level_up_disabled.png'
import { useState } from 'react'

import { sounds } from 'utils/sounds'
import './InfoMenu.css'
import { formatValueForButtons } from 'utils/formatValueForButtons'

const InfoMenu = ({
    telegramId,
    closeMenu,
    playerUpgrades,
    fetchPlayerUpgrades,
    spendCoinsForUpgrade,
    coins,
    selectedCharacter,
    handleFlyCoin,
    disabledButtons,
    openAchieveMenu,
    step = null,
    handleNextStep = null,
}) => {
    const [loading, setLoading] = useState({})

    const getUpgradeData = upgradeType => {
        return playerUpgrades.find(upgrade => upgrade.upgrade_type === upgradeType)
    }

    const handleClick = async (upgradeType, e) => {
        if (step === 19) return

        const targetButton = e.currentTarget
        handleFlyCoin(targetButton)

        sounds.approve.play()

        const { next_level_cost } = getUpgradeData(upgradeType)
        spendCoinsForUpgrade(next_level_cost)
        setLoading(prevLoading => ({ ...prevLoading, [upgradeType]: true }))

        try {
            await upgradePlayerBuffs(telegramId, upgradeType)
            await fetchPlayerUpgrades(telegramId)
        } catch (error) {
            console.error('Error during upgrade:', error)
        } finally {
            setLoading(prevLoading => ({ ...prevLoading, [upgradeType]: false }))
            if (step) {
                handleNextStep()
                closeMenu(true)
            }
        }
    }

    const handleOpenAchieveMenu = () => {
        openAchieveMenu()
    }

    const handleCloseMenu = () => {
        if (step) return
        sounds.close.play()
        closeMenu()
    }

    return (
        <div className="info-menu-overlay" onClick={handleCloseMenu}>
            <img src={require('images/leaf_cut.png')} alt="" className="leaf-image" />
            <div className="info-menu-content" onClick={e => e.stopPropagation()}>
                <div className="info-header">
                    <div className="menu-item">
                        <div className={clsx('menu-btn', { disabled: disabledButtons })} onClick={!disabledButtons ? null : null}>
                            <img src={LeftMenuIconDisabled} alt="Menu" className="menu-icon" />
                        </div>

                        <div
                            className={clsx('menu-btn', { disabled: disabledButtons })}
                            onClick={!disabledButtons ? handleOpenAchieveMenu : null}
                        >
                            <img src={RightMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                    </div>
                    <div
                        className={clsx('close-menu-btn', { disabled: disabledButtons })}
                        onClick={!disabledButtons ? handleCloseMenu : null}
                    >
                        <img src={require('images/icons/close.png')} alt="Close" className="close-menu-icon" />
                    </div>
                </div>
                <div className="info-table-wrapper">
                    <table className="info-table">
                        <tbody className="info-table-body">
                            <tr className="info-table-row">
                                <td className="info-table-avatar-cell">
                                    <IconBackground level={getUpgradeData('storage_power')?.level || 0}>
                                        <div className="img_wrapper">
                                            <img src={JarFull} alt="Jar with coins" width={40} className="jar_img" />
                                        </div>
                                    </IconBackground>
                                </td>
                                <td className="info-table-description-cell">
                                    <div className="info_menu_table_cell_content">
                                        <h4 className="info_menu_title">STORAGE</h4>
                                        <p>
                                            {formatValueForButtons(getUpgradeData('storage_power')?.current_power || 0)} {' >> '}
                                            {getUpgradeData('storage_power')?.next_power
                                                ? formatValueForButtons(getUpgradeData('storage_power')?.next_power)
                                                : 'MAX'}
                                        </p>
                                        <p className="cta">Expand the size of $COGA STORAGE</p>
                                    </div>
                                </td>
                                <td className="info-table-button-cell">
                                    <button
                                        className={clsx('achieve-button achieve-action-btn', {
                                            'achieve-blue-action-btn':
                                                getUpgradeData('storage_power')?.next_level_cost === 0 ||
                                                coins - getUpgradeData('storage_power')?.next_level_cost < 0,
                                            'achieve-green-action-btn':
                                                getUpgradeData('storage_power')?.next_level_cost > 0 &&
                                                coins - getUpgradeData('storage_power')?.next_level_cost >= 0,
                                        })}
                                        onClick={e => handleClick('storage_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['storage_power'] ||
                                            coins - getUpgradeData('storage_power')?.next_level_cost < 0 ||
                                            getUpgradeData('storage_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img src={require('images/icons/coga_token.png')} alt="Coin" className="coin-btn-icon" />{' '}
                                        <p className="achieve_button_text achieve_button_text--small">
                                            {getUpgradeData('storage_power')?.next_level_cost
                                                ? formatValueForButtons(getUpgradeData('storage_power')?.next_level_cost)
                                                : 'MAX'}
                                        </p>
                                    </button>
                                </td>
                            </tr>
                            <tr className="info-table-row">
                                <td className="info-table-avatar-cell">
                                    <IconBackground level={getUpgradeData('tap_power')?.level || 0}>
                                        <div className="img_wrapper">
                                            <div className={`pet-img ${selectedCharacter}`}></div>
                                        </div>
                                    </IconBackground>
                                </td>
                                <td className="info-table-description-cell">
                                    <div className="info_menu_table_cell_content">
                                        <h4 className="info_menu_title">TAP TAP </h4>
                                        <p>
                                            {getUpgradeData('tap_power')?.current_power || '0'} {' >> '}
                                            {getUpgradeData('tap_power')?.next_power || 'MAX'}
                                        </p>
                                        <p className="cta">Increase Poomby Tap</p>
                                    </div>
                                </td>
                                <td className="info-table-button-cell">
                                    <button
                                        className={clsx(
                                            'achieve-button achieve-action-btn',
                                            {
                                                'achieve-blue-action-btn':
                                                    getUpgradeData('tap_power')?.next_level_cost === 0 ||
                                                    coins - getUpgradeData('tap_power')?.next_level_cost < 0,
                                                'achieve-green-action-btn':
                                                    getUpgradeData('tap_power')?.next_level_cost > 0 &&
                                                    coins - getUpgradeData('tap_power')?.next_level_cost >= 0,
                                            },
                                            { 'achieve-green-action-btn--rised': step },
                                        )}
                                        onClick={e => handleClick('tap_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['tap_power'] ||
                                            coins - getUpgradeData('tap_power')?.next_level_cost < 0 ||
                                            getUpgradeData('tap_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img src={require('images/icons/coga_token.png')} alt="Coin" className="coin-btn-icon" />{' '}
                                        <p className="achieve_button_text achieve_button_text--small">
                                            {getUpgradeData('tap_power')?.next_level_cost
                                                ? formatValueForButtons(getUpgradeData('tap_power')?.next_level_cost)
                                                : 'MAX'}
                                        </p>
                                    </button>{' '}
                                    {step === 19 && <div className="tutor_info_menu_step_backdrop" />}
                                </td>
                            </tr>
                            <tr className="info-table-row">
                                <td className="info-table-avatar-cell">
                                    <IconBackground level={getUpgradeData('auto_tap_power')?.level || 0}>
                                        <div className="img_wrapper">
                                            <img src={CoinsImg} alt="Auto Tap" className="auto-tap-img" />
                                        </div>
                                    </IconBackground>
                                </td>
                                <td className="info-table-description-cell">
                                    <div className="info_menu_table_cell_content">
                                        <h4 className="info_menu_title">AUTO TAP </h4>
                                        <p>
                                            {getUpgradeData('auto_tap_power')?.current_power || '0'} {' >> '}
                                            {getUpgradeData('auto_tap_power')?.next_power || 'MAX'}
                                        </p>
                                        <p className="cta">Farm more $COGA TOKENS</p>
                                    </div>
                                </td>
                                <td className="info-table-button-cell">
                                    <button
                                        className={clsx('achieve-button achieve-action-btn ', {
                                            'achieve-button  achieve-blue-action-btn':
                                                getUpgradeData('auto_tap_power')?.next_level_cost === 0 ||
                                                coins - getUpgradeData('auto_tap_power')?.next_level_cost < 0,
                                            'achieve-green-action-btn':
                                                getUpgradeData('auto_tap_power')?.next_level_cost > 0 &&
                                                coins - getUpgradeData('auto_tap_power')?.next_level_cost >= 0,
                                        })}
                                        onClick={e => handleClick('auto_tap_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['auto_tap_power'] ||
                                            coins - getUpgradeData('auto_tap_power')?.next_level_cost < 0 ||
                                            getUpgradeData('auto_tap_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img src={require('images/icons/coga_token.png')} alt="Coin" className="coin-btn-icon" />{' '}
                                        <p className="achieve_button_text achieve_button_text--small">
                                            {getUpgradeData('auto_tap_power')?.next_level_cost
                                                ? formatValueForButtons(getUpgradeData('auto_tap_power')?.next_level_cost)
                                                : 'MAX'}
                                        </p>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default InfoMenu
