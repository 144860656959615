import React, { useState, useEffect } from 'react'
import './Start.css'

import infoIcon from 'images/icons/info_button.png'
import { clsx } from 'clsx'
import InformationSection from 'components/InformationSection/InformationSection'
import Leaf from 'images/leaf_ui_nickname_form.png'
import Logo from 'images/logo.png'

function Start({ onPlay, isSubscribed, tutorial, handleNextStep, step = null }) {
    const [showPopup, setShowPopup] = useState(false)
    const [popupContent, setPopupContent] = useState('')
    const [buttonText, setButtonText] = useState('')

    useEffect(() => {
        if (tutorial && isSubscribed && step === 1) {
            handleNextStep()
        }
    }, [tutorial, isSubscribed, step, handleNextStep])

    const handleInfoClick = () => {
        setPopupContent(
            <div className="popup-text-content">
                <p className="popup_title_main">
                    <b>
                        How to Become the Ultimate <br /> POOMB CO-OWNER?
                    </b>
                </p>
                <p className="popup_title_secondary">Subscribe and invite your friends to join the adventure today!</p>
                <div className="popup_text_wrapper">
                    <p className="popup_text">
                        Unlock exclusive rewards, including unique characters, rare add-ons, game bonuses, $COGA tokens, and in-game
                        currency that others won’t have access to!
                    </p>
                    <p className="popup_text">
                        This is a limited-time offer for early adopters who start their journey in the Poomb Creatures ecosystem now. Don’t
                        miss your chance to stand out and lead the game!
                    </p>
                </div>
            </div>,
        )
        setButtonText('COOL')
        setShowPopup(true)
    }

    const handleClosePopup = () => setShowPopup(false)

    return (
        <div className="page">
            <div className="container">
                <div className="start-page-wrapper">
                    <div className="subscribe-wrapper">
                        <img src={Leaf} alt="Leaf" className="level_leaf_image" />

                        <div className="logo_wrapper">
                            <img src={Logo} alt="Poomb logo" className="logo_img" width={151} />
                        </div>
                        <div className="subscribe-content">
                            <div className="icon" onClick={handleInfoClick}>
                                <img src={infoIcon} alt="Info Icon" className="info-icon" width={30} />
                            </div>
                            <p className="info_title">"IN POOMB WE TRUST, IN POOMB WE EARN"</p>
                            <div className="info_part">
                                <p className="info_question">What's $COGA TOKEN?</p>
                                <p className="info_text">
                                    $COGA is the native token driving the <b>Poomb Ecosystem</b>, a bridge between Web2 and Web3. It rewards
                                    early adopters and offers endless opportunities in gaming and beyond.
                                </p>
                            </div>

                            <div className="info_part">
                                <p className="info_question">What's POOMB Creatures?</p>
                                <p className="info_text">
                                    This Telegram clicker game is just the beginning! Soon, a full-scale mobile game on iOS/Android will
                                    launch, packed with advanced features and gameplay. By starting now, you’re positioning yourself at the
                                    forefront of this exciting journey.
                                </p>
                                <p className="info_text">
                                    Don’t miss the chance to be part of the early stage— develop your Poomb Pet, earn $COGA, and secure your
                                    spot in the next era of gaming!
                                </p>
                            </div>
                        </div>
                        <button
                            className={clsx(
                                'btn-action',
                                {
                                    disabled: isSubscribed,
                                },
                                { not_visible: (isSubscribed && tutorial) || isSubscribed },
                            )}
                            onClick={() => window.open('https://t.me/poomb_game/44', '_blank')}
                            disabled={isSubscribed}
                        >
                            Subscribe
                        </button>
                        {tutorial && (
                            <button
                                className={clsx(
                                    'btn-accept',
                                    {
                                        disabled: !isSubscribed,
                                    },
                                    { not_visible: !isSubscribed && tutorial },
                                )}
                                onClick={() => handleNextStep()}
                                disabled={!isSubscribed}
                            >
                                Start
                            </button>
                        )}
                        {!tutorial && isSubscribed && (
                            <div className="action_wrapper_start">
                                <button
                                    className={clsx('btn-accept', {
                                        disabled: !isSubscribed,
                                    })}
                                    onClick={onPlay}
                                    disabled={!isSubscribed}
                                >
                                    Play
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showPopup && <InformationSection onClose={handleClosePopup} content={popupContent} buttonText={buttonText} />}
        </div>
    )
}

export default Start
