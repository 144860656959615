import CharacterSelection from "components/CharacterSelection/CharacterSelection";
import Gameplay from "components/Gameplay/Gameplay";
import Loader from "components/Loader/Loader";
import Start from "components/Start/Start";
import {
	EnergyTimerProvider,
	PlayerProvider,
	ShopPriceProvider,
	ShopProvider,
} from "context";
import React, { useEffect, useState, useCallback } from "react";
import "App.css";
import {
	createPlayer,
	getPlayer,
	notifyUserInactive,
	sendTgPremiumStatus,
} from "api/playersApi";
import "index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import startSound from "assets/sounds/Poomb_HQ_main_start_game.wav";
import Tutorial from "components/Tutorial/Tutorial";
import phoneIcon from "images/icons/phone.png";
import { FaExclamationTriangle } from "react-icons/fa";
import useWindowFocus from "use-window-focus";
import { sounds } from "utils";

const App = () => {
	const [currentPage, setCurrentPage] = useState("loading");
	const [selectedCharacter, setSelectedCharacter] = useState(null);
	const [playerData, setPlayerData] = useState(null);
	const [userId, setUserId] = useState(null);
	const [isSubscribed, setIsSubscribed] = useState(true);
	const [firstTimeSubscribed, setFirstTimeSubscribed] = useState(() => {
		return localStorage.getItem("firstTimeSubscribed") === "true";
	});
	const [isLoadingPage, setIsLoadingPage] = useState(true);
	const [isPlayerCreated, setIsPlayerCreated] = useState(false);
	const windowFocused = useWindowFocus();

	const [isPortrait, setIsPortrait] = useState(
		window.matchMedia("(orientation: portrait)").matches,
	);
	const [isSupportedDevice, setIsSupportedDevice] = useState(true);

	const [startAudio] = useState(() => new Audio(startSound));

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const isMobileDevice = useCallback(() => {
		const userAgent = navigator.userAgent;
		const isMobileUserAgent =
			/Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone|webOS|Mobile/i.test(
				userAgent,
			) && !/iPad|Tablet/i.test(userAgent);
		const isSmallScreen = window.innerWidth <= 1024;
		return isMobileUserAgent && isSmallScreen;
	}, []);

	useEffect(() => {
		const checkDeviceAndUpdatePage = () => {
			const isMobile = isMobileDevice();

			if (!isMobile) {
				setIsSupportedDevice(false);
				setCurrentPage("unsupported");
				return;
			}
			if (isMobile) {
				setIsSupportedDevice(true);
			}
		};

		checkDeviceAndUpdatePage();

		const handleResize = () => {
			checkDeviceAndUpdatePage();
		};

		window.addEventListener("resize", handleResize);

		if (window.screen.orientation && window.screen.orientation.lock) {
			window.screen.orientation
				.lock("portrait")
				.then(() => {
					console.log("Screen orientation locked to 'portrait'.");
				})
				.catch((err) => {
					console.error("Failed to lock screen orientation:", err);
				});
		} else {
			console.error("Screen orientation locking API is not supported.");
		}

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isMobileDevice]);

	useEffect(() => {
		const checkOrientationAndSupport = () => {
			const isPortraitNow = window.matchMedia(
				"(orientation: portrait)",
			).matches;
			setIsPortrait(isPortraitNow);
		};

		checkOrientationAndSupport();

		window.addEventListener("resize", checkOrientationAndSupport);
		window
			.matchMedia("(orientation: portrait)")
			.addEventListener("change", checkOrientationAndSupport);

		return () => {
			window.removeEventListener("resize", checkOrientationAndSupport);
			window
				.matchMedia("(orientation: portrait)")
				.removeEventListener("change", checkOrientationAndSupport);
		};
	}, []);

	const initializeTelegramWebApp = useCallback(async () => {
		try {
			if (window.Telegram?.WebApp) {
				window.Telegram.WebApp.ready();
				window.Telegram.WebApp.expand();

				const user = window.Telegram.WebApp.initDataUnsafe?.user;

				const userToUse = user || {
					id: process.env.REACT_APP_USER_ID,
					first_name: "TestUser",
					username: "test_user",
					// is_premium: true,
				};

				setUserId(userToUse.id);

				setTimeout(() => {
					window.Telegram.WebApp.expand();
				}, 100);

				try {
					const existingPlayer = await getPlayer(userToUse.id);
					await sendTgPremiumStatus(userToUse.id, userToUse.is_premium);

					if (existingPlayer) {
						setPlayerData(existingPlayer);
						setIsPlayerCreated(true);
					}
				} catch (error) {
					if (error.response && error.response.status === 404) {
						await createPlayer(userToUse.id);
						setIsPlayerCreated(true);
					} else {
						console.error("Error checking or creating player:", error);
						setCurrentPage("error");
					}
				}
			}
		} catch (error) {
			console.error("Error initializing Telegram WebApp:", error);
			setCurrentPage("error");
		}
	}, []);

	useEffect(() => {
		const updateHeight = () => {
			const height = window.innerHeight;

			document.documentElement.style.height = `${height}px`; // html
			document.body.style.height = `${height}px`; // body
		};

		initializeTelegramWebApp().then(() => {
			updateHeight();
		});

		window.addEventListener("resize", updateHeight);

		return () => {
			window.removeEventListener("resize", updateHeight);
		};
	}, []);

	const fetchPlayerData = useCallback(async () => {
		if (!userId || !isPlayerCreated) return;

		try {
			const [playerDataResponse /* , subscribed */] = await Promise.all([
				getPlayer(userId) /* , checkSubscription(userId) */,
			]);
			setPlayerData(playerDataResponse);

			// const firstTimeSubscribedLocal = localStorage.getItem('firstTimeSubscribed')

			/*       if ((!firstTimeSubscribedLocal || firstTimeSubscribedLocal === 'true') && subscribed) {
                setFirstTimeSubscribed(true)
                localStorage.setItem('firstTimeSubscribed', 'true')
            } else {
                setFirstTimeSubscribed(false)
            } */

			// setIsSubscribed(subscribed)
		} catch (error) {
			console.error(
				"Error loading player data or checking subscription:",
				error,
			);
			setCurrentPage("error");
		}
	}, [userId, isPlayerCreated]);

	useEffect(() => {
		if (isLoadingPage && isPlayerCreated && userId) {
			fetchPlayerData();
		}
	}, [isLoadingPage, userId, isPlayerCreated, fetchPlayerData]);

	const updatePage = useCallback(() => {
		if (isSubscribed && firstTimeSubscribed) {
			setCurrentPage("start");
		} else if (playerData?.character && isSubscribed) {
			setSelectedCharacter(playerData.character.name);
			setCurrentPage("gameplay");
		} else if (!isSubscribed) {
			setCurrentPage("start");
		} else if (isSubscribed && !playerData?.character) {
			setCurrentPage("character-selection");
		} else {
			setCurrentPage("start");
		}
	}, [isSubscribed, firstTimeSubscribed, playerData]);

	useEffect(() => {
		if (!isLoadingPage) {
			updatePage();
		}
	}, [updatePage, isLoadingPage]);

	const handleLoaderComplete = useCallback(() => {
		setIsLoadingPage(false);
	}, []);

	const resetFirstTimeSubscription = useCallback(() => {
		setFirstTimeSubscribed(false);
		localStorage.setItem("firstTimeSubscribed", "false");
	}, []);

	const handlePlay = useCallback(async () => {
		try {
			await startAudio.play();
		} catch (error) {
			console.error("Playback failed:", error);
		}

		try {
			await fetchPlayerData();

			if (playerData && playerData.character && isSubscribed) {
				setSelectedCharacter(playerData.character.name);
				resetFirstTimeSubscription();
				setCurrentPage("gameplay");
			} else {
				setCurrentPage("character-selection");
				resetFirstTimeSubscription();
			}
		} catch (error) {
			console.error("Error loading character:", error);
			setCurrentPage("error");
		}
	}, [
		fetchPlayerData,
		playerData,
		isSubscribed,
		startAudio,
		resetFirstTimeSubscription,
	]);

	const handleCharacterSelect = useCallback(
		async (character) => {
			setSelectedCharacter(character);

			try {
				const updatedPlayerData = await getPlayer(userId);
				setPlayerData(updatedPlayerData);
				setCurrentPage("gameplay");
			} catch (error) {
				console.error("Error adding character:", error);
				setCurrentPage("error");
			}
		},
		[userId],
	);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				fetchPlayerData();
				Object.values(sounds).forEach((soundPool) => {
					soundPool.unload();
					soundPool.preload();
				});
			} else if (document.visibilityState === "hidden") {
				notifyUserInactive(userId);
			}
		};

		const handleBeforeUnload = () => {
			notifyUserInactive(userId);
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [fetchPlayerData, userId]);

	useEffect(() => {
		if (windowFocused) {
			Object.values(sounds).forEach((soundPool) => {
				soundPool.unload();
				soundPool.preload();
			});
		}
	}, [windowFocused]);

	return (
		<PlayerProvider player={playerData}>
			<EnergyTimerProvider>
				<ShopProvider>
					<ShopPriceProvider>
						<div className="App">
							{isLoadingPage && currentPage !== "unsupported" && playerData && (
								<Loader
									setLoadingComplete={handleLoaderComplete}
									player={playerData}
								/>
							)}
							{!isPortrait && isSupportedDevice ? (
								<div className="black-rotate-wrapper">
									<img src={phoneIcon} alt="Phone" className="phone-icon" />
									Please rotate your phone.
								</div>
							) : (
								<>
									{!isSupportedDevice && currentPage === "unsupported" && (
										<div className="unsupported">
											This application is only supported on mobile phones.
										</div>
									)}
									{playerData?.character && (
										<>
											{!isLoadingPage &&
												playerData &&
												currentPage === "start" && (
													<Start
														onPlay={handlePlay}
														isSubscribed={isSubscribed}
														tutorial={!playerData.character}
													/>
												)}
											{!isLoadingPage &&
												currentPage === "character-selection" && (
													<CharacterSelection
														player={playerData}
														onCharacterSelected={handleCharacterSelect}
													/>
												)}
											{!isLoadingPage && currentPage === "gameplay" && (
												<Gameplay
													player={playerData}
													selectedCharacter={selectedCharacter}
													toggleSubscribe={setIsSubscribed}
													toggleCurrentPage={setCurrentPage}
													userId={userId}
												/>
											)}
										</>
									)}
									{currentPage === "error" && (
										<div className="error">
											<FaExclamationTriangle size={50} />
											Error loading data.
											<br /> Please try again later.
										</div>
									)}
									{playerData &&
										!playerData?.character &&
										!isLoadingPage &&
										currentPage !== "unsupported" &&
										currentPage !== "error" && (
											<Tutorial
												isSubscribed={isSubscribed}
												selectCharacter={handleCharacterSelect}
												userId={userId}
											/>
										)}
									<ToastContainer />
								</>
							)}
						</div>
					</ShopPriceProvider>
				</ShopProvider>
			</EnergyTimerProvider>
		</PlayerProvider>
	);
};

export default App;
