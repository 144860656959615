import clsx from 'clsx'
import './PlayStep.css'
import logo from 'images/logo.png'

const PlayStep = ({ handleNextStep }) => {
    return (
        <div className="play_step_wrapper">
            <div className="play_step_logo_wrapper">
                <img src={logo} className="play_step_logo" alt="Logo" />
            </div>
            <div className="play_step_button_wrapper">
                <button className={clsx('btn-accept', 'btn-accept-play')} onClick={() => handleNextStep()}>
                    Play
                </button>
            </div>
        </div>
    )
}

export default PlayStep
