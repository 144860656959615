import axios from "../utils/axios";

export const getCogaPointsPrice = async () => {
	const { data } = await axios.get("/prices/get_coga_points_price");
	return data;
};

export const getEnergyPrice = async () => {
	const { data } = await axios.get("/prices/get_energy_price");
	return data;
};
