import { createContext, useContext, useMemo } from "react";

export const PlayerContext = createContext(null);

export const PlayerProvider = ({ children, player }) => {
	const value = useMemo(
		() => ({ ...player }),
		[player],
	);

	return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
};

export const usePlayer = () => {
	const context = useContext(PlayerContext);

	if (!context) {
		throw new Error("usePlayer must be used within a PlayerProvider");
	}

	return context;
};
