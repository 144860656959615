import React, { useState, useEffect, useRef } from 'react'
import './LevelInfo.css'

import { toast } from 'react-toastify'
import { getPlayerInvitingStatus, getPlayerLevel } from 'api/playersApi'
import { sounds } from 'utils'

import clsx from 'clsx'
import RankInfo from 'components/RankInfo/RankInfo'
import InformationSection from 'components/InformationSection/InformationSection'
import LevelProgressBar from './LevelProgressBar/LevelProgressBar'
import Leaf from 'images/leaf_ui_nickname_form.png'
import { levelUpData } from 'constants/levelUpData'
import { referralData } from 'constants/referralData'

const BOT_URL = process.env.REACT_APP_BOT_URL

const LevelInfo = ({ closePopup, selectedCharacter, player, level, step = null, levelProgress, tutorial }) => {
    const [showPopup, setShowPopup] = useState(false)

    const [popupContent, setPopupContent] = useState('')
    const [showGiftPopup, setShowGiftPopup] = useState(false)
    // const [giftPopupContent, setGiftPopupContent] = useState('')
    const [invitingStatus, setInvitingStatus] = useState(null)
    const [playerLevelInfo, setPlayerLevelInfo] = useState(null)
    const [showRankInfo, setShowRankInfo] = useState(false)
    const lvlTableBodyRef = useRef(null)
    const [isGradientTopVisible, setIsGradientTopVisible] = useState(false)
    const [isGradientBottomVisible, setIsGradientBottomVisible] = useState(false)

    useEffect(() => {
        if (step) return

        const fetchPlayerInvitingStatusAndLevelInfo = async () => {
            try {
                const [status, levelInfo] = await Promise.all([
                    getPlayerInvitingStatus(player.telegram_id),
                    getPlayerLevel(player.telegram_id),
                ])

                setInvitingStatus(status)
                setPlayerLevelInfo(levelInfo)
            } catch (error) {
                console.error('Failed to fetch inviting status:', error)
                toast.error('Failed to load inviting status. Please try again later.', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

        fetchPlayerInvitingStatusAndLevelInfo()
    }, [player?.telegram_id, step])

    useEffect(() => {
        if (!showGiftPopup) return

        const handleScroll = () => {
            if (!lvlTableBodyRef.current) return
            const { scrollTop, scrollHeight, clientHeight } = lvlTableBodyRef.current

            setIsGradientTopVisible(scrollTop > 0)

            setIsGradientBottomVisible(scrollTop + clientHeight < scrollHeight)
        }

        const tableBody = lvlTableBodyRef.current
        if (tableBody) {
            tableBody.addEventListener('scroll', handleScroll)
            handleScroll() // Initialize state on mount
        }

        return () => {
            if (tableBody) {
                tableBody.removeEventListener('scroll', handleScroll)
            }
        }
    }, [showGiftPopup])

    const handleCopy = () => {
        if (step) return
        const referralLink = `${BOT_URL}${player.referral_code}`
        sounds.approve.play()
        navigator.clipboard
            .writeText(referralLink)
            .then(() => {
                toast.success('Referral link has been copied to your clipboard!', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
            .catch(error => {
                toast.error('Failed to copy the link. Please try again.', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const handleTelegramShare = () => {
        if (step) return
        sounds.approve.play()
        const referralLink = `${BOT_URL}${player.referral_code}`
        const message = `Join Poomb Creatures – The Start of Your Web2 + Web3 Adventure!\n\nStep into the Poomb Space ecosystem with my referral link and start earning rewards.\n\nThis is just the beginning—explore Web2 and Web3 integration, our unique mining machine, and much more!\n\n${referralLink}`

        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`
        window.open(telegramUrl, '_blank')
    }

    // for testing
    // invitingStatus.current_level = 4

    const openPopup = () => {
        if (step) return

        sounds.close.play()
        setPopupContent(
            <div>
                <p className="popup_title_main">"EARN REWARDS WITH OUR REFERRAL PROGRAM!"</p>
                <p className="popup_title_secondary">
                    Don’t miss your chance to grab your share of up to 7% from the total amount of $COGA tokens!
                </p>
                <p className="popup_text">Invite friends using your unique referral link and secure</p>
                <div className="gift-content">
                    <div className="friends_table_header">
                        <div className="position_column">lvl</div>
                        <div className="friends_row_info">
                            <div>invited</div>
                            <div>$coga</div>
                        </div>
                    </div>
                    <div className="lvl_table_body">
                        {referralData.map(item => (
                            <div
                                key={item.level}
                                className={clsx('gift-content-lvl-row', {
                                    'gift-content-lvl-row--passed': invitingStatus.current_level >= item.level,
                                })}
                            >
                                <div className="position_column">{item.level}</div>
                                <div className="friends_row_info">
                                    <div>
                                        +{item.invited} {item.invited > 1 ? 'friends ' : 'friend'}
                                    </div>
                                    <div>{item.scoga.toLocaleString('de-DE')}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <p className="popup_text popup_text_lvl_info">
                    Be an early adopter and start earning today! The more friends you invite, the more tokens you collect.
                </p>
                <p className="popup_text">Join now and be part of the next big thing in gaming!</p>
            </div>,
        )
        setShowPopup(true)
    }

    const closePopupHandler = () => {
        sounds.close.play()
        setShowPopup(false)
    }

    const openGiftPopup = () => {
        if (step) {
            return
        }
        sounds.close.play()
        // setGiftPopupContent(
        //     <div className="gift-content">
        //         <div className="lvl_table_header">
        //             <div>lvl</div>
        //             <div>taps</div>
        //         </div>
        //         <div className="lvl_table_body" ref={lvlTableBodyRef}>
        //             {levelUpData.map(item => (
        //                 <div key={item.level} className="gift-content-lvl-row">
        //                     <div>{item.level}</div>
        //                     <div>{item.taps.toLocaleString('de-DE')}</div>
        //                 </div>
        //             ))}
        //         </div>
        //         {isGradientTopVisible && <div className="gradient-top"></div>}
        //         {isGradientBottomVisible && <div className="gradient-bottom"></div>}
        //     </div>,
        // )
        setShowGiftPopup(true)
    }

    const closeGiftPopupHandler = () => {
        sounds.close.play()
        setShowGiftPopup(false)
    }

    const openRankInfo = () => {
        if (step) return
        setShowRankInfo(true)
    }

    const closeRankInfo = () => {
        sounds.close.play()
        setShowRankInfo(false)
    }

    return (
        <div className="level-info-overlay">
            <div className="level-info-content">
                <img src={Leaf} alt="Leaf" className="level_leaf_image" />
                <div className="level-info-wrapper ">
                    <div className={clsx('level-close-btn', { close_btn_tutorial: step })} onClick={closePopup}>
                        <img src={require('images/icons/close.png')} alt="Close" className="close-icon" />
                    </div>
                    <div className="account-pet-wrapper">
                        <div className="level-item-wrapper">
                            <div className="pet-wrapper">
                                <div className={`selected-pet-icon ${selectedCharacter}`}></div>
                            </div>
                            <h2 className="player-level-value">{playerLevelInfo ? playerLevelInfo.current_lvl : level} LVL</h2>
                        </div>
                    </div>

                    <div className="progress-level-info">
                        <div className="level-bar-wrapper">
                            <LevelProgressBar levelProgress={levelProgress} playerLevelInfo={playerLevelInfo} tutorial={tutorial} />
                            <div className="info-level-btn" onClick={openGiftPopup}>
                                <img src={require('images/icons/info_button.png')} alt="Info" className="info-level-icon" />
                            </div>
                        </div>
                        <p className="progress-level-info-text">
                            {levelProgress.clicks_to_next_lvl - levelProgress.player_total_clicks} taps left to move to the next level
                        </p>
                    </div>

                    <div className="information-wrapper">
                        <p className="information-title">PLAY WITH FRIENDS</p>
                        <p className="information-text">
                            Don’t miss your chance <br />
                            to grab your share of up to 7% <br />
                            from the total amount of $COGA tokens!
                        </p>
                    </div>

                    <div className="action-wrapper">
                        {step === 16 && <div className="tutor_lvl_step_backdrop" />}
                        <div className={clsx('rank-wrapper', { rang_wrapper_tutorial: step })} onClick={openRankInfo}>
                            <div className="rank-icon-img">
                                <img src={require('images/icons/rank.png')} alt="Rank" className="rank-icon" />
                                <p className="action-wrapper-text">RANK</p>
                            </div>
                        </div>
                        <div className="friend-wrapper" onClick={openPopup}>
                            <div className="friend-title">
                                <h3 className="friends-value-text" title={step ? 3 : invitingStatus?.next_reward?.friends_needed}>
                                    {step ? 3 : invitingStatus?.next_reward?.friends_needed}
                                </h3>
                                <p className="action-wrapper-text">
                                    FRIENDS <br />
                                    LEFT
                                </p>
                            </div>
                        </div>
                        <div className="invite-wrapper" onClick={handleTelegramShare}>
                            <div className="plus-icon-img">
                                <img src={require('images/icons/invite.png')} alt="Rank" className="plus-icon" />
                                <p className="action-wrapper-text">INVITE</p>
                            </div>
                        </div>
                    </div>
                    <div className="invite-friends-wrapper" onClick={handleCopy}>
                        <p>INVITE FRIENDS</p>
                    </div>
                </div>
            </div>

            {showPopup && <InformationSection onClose={closePopupHandler} content={popupContent} buttonText="CooL" />}

            {showGiftPopup && (
                <InformationSection onClose={closeGiftPopupHandler} buttonText="CooL">
                    <div className="gift-content">
                        <div className="lvl_table_header">
                            <div>lvl</div>
                            <div>taps</div>
                        </div>
                        <div className="table_gradient_wrapper">
                            <div className="lvl_table_body" ref={lvlTableBodyRef}>
                                <div className="lvl_table_body_container">
                                    {levelUpData.map(item => (
                                        <div key={item.level} className="gift-content-lvl-row">
                                            <div>{item.level}</div>
                                            <div>{item.taps.toLocaleString('de-DE')}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isGradientTopVisible && <div className="gradient-top"></div>}
                            {isGradientBottomVisible && <div className="gradient-bottom"></div>}
                        </div>
                    </div>
                </InformationSection>
            )}

            {showRankInfo && <RankInfo closeRankInfo={closeRankInfo} telegram_id={player.telegram_id} player_nickname={player.nick_name} />}
        </div>
    )
}

export default LevelInfo
