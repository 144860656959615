import clsx from "clsx";
import { useCallback } from "react";

import { createCogaPointsInvoice, createEnergyInvoice } from "api/paymentsApi";
import { ShopType } from "constants/shop";
import { useWebSocketMessage } from "context";
import cogas from "images/shop/currency/cogas.svg";
import lightning from "images/shop/product/lightning.svg";
import telegramStar from "images/telegram-star.svg";
import { CoinsService } from "services";

import "./Shop.css";

const Icon = {
	[ShopType.ENERGY]: lightning,
	[ShopType.COGAS]: cogas,
};

const Bullet = ({ type = ShopType.ENERGY, count, price, telegramId }) => {
	const service = new CoinsService(count);
	const { sendMessage } = useWebSocketMessage();

	const requestPlayerEnergy = useCallback(() => {
		const requestData = {
			operation: "read_player_energy",
			data: {
				telegram_id: telegramId,
			},
		};
		sendMessage(JSON.stringify(requestData));
	}, [telegramId, sendMessage]);

	const requestPlayer = useCallback(() => {
		const requestData = {
			operation: "read_player",
			data: {
				telegram_id: telegramId,
			},
		};
		sendMessage(JSON.stringify(requestData));
	}, [telegramId, sendMessage]);

	const onClickHandler = useCallback(async () => {
		try {
			let response;

			if (type === ShopType.ENERGY) {
				response = await createEnergyInvoice({
					telegram_id: telegramId,
					energy_reward: count,
					amount_stars: price,
				});
			} else {
				response = await createCogaPointsInvoice({
					telegram_id: telegramId,
					coga_points_reward: count,
					amount_stars: price,
				});
			}

			if (response?.invoice_url) {
				if (window.Telegram?.WebApp) {
					window.Telegram.WebApp.openInvoice(response.invoice_url, (status) => {
						if (status === "paid") {
							requestPlayerEnergy();
							requestPlayer();
						}
					});
				} else {
					window.open(response.invoice_url, "_blank");
				}
			} else {
				console.error("Invoice URL not found in response");
			}
		} catch (error) {
			console.error("Failed to create invoice:", error);
		}
	}, [count, price, telegramId, type, requestPlayerEnergy, requestPlayer]);

	return (
		<div className="shop-bullet">
			<p className={clsx("shop-bullet__count", type)}>
				{service.getFormatedCoinsShort()}
			</p>
			<img src={Icon[type]} alt={type} />
			<button type="button" className="shop-buy-btn" onClick={onClickHandler}>
				<img height={20} width={20} src={telegramStar} alt={type} />{" "}
				<span className="shop-bullet__price">{price}</span>
			</button>
		</div>
	);
};

export default Bullet;
