import {
	useEnergy,
	useEnergyTimer,
	usePlayer,
	useWebSocketMessage,
} from "context";
import React from "react";

import EnergyTimer from "components/EnergyTimer/EnergyTimer";
import energyIcon from "images/shop/currency/energy_icon.png";

const EnergyInfo = () => {
	const { nextUpdateTime } = useEnergyTimer();
	const { sendMessage } = useWebSocketMessage();
	const { energy, setEnergy, maxEnergyValue } = useEnergy();
	const player = usePlayer();

	const isMaxEnergy = energy === maxEnergyValue;

	return (
		<div className="shop-header-info-element-container energy">
			<div className="shop-header-info-element-inner-container">
				<img
					className="shop-header-info-element-coin-image energy"
					src={energyIcon}
					alt="Energy coin"
				/>
				<span className="shop-header-info-element-text shop-header-info-element-coga-count">
					{Math.max(0, energy)}/{maxEnergyValue}
				</span>
			</div>
			{!isMaxEnergy && (
				<EnergyTimer
					className="shop-energy-timer"
					energy={energy}
					setEnergy={setEnergy}
					maxEnergyValue={maxEnergyValue}
					sendMessage={sendMessage}
					player={player}
					tutorial={false}
					nextUpdateTime={nextUpdateTime}
				/>
			)}
		</div>
	);
};

export default EnergyInfo;
