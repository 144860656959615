import { useShop } from "context";
import backButton from "images/shop/back.png";

import EnergyInfo from "./EnergyInfo";
import CogaInfo from "./CogaInfo";

const ShopHeader = () => {
	const { setOpen } = useShop();

	const onBackButtonHandler = () => {
		setOpen(false);
	};

	return (
		<div className="shop-header">
			<EnergyInfo />
			<CogaInfo />
			<button
				type="button"
				className="shop-back-btn"
				onClick={onBackButtonHandler}
			>
				<img src={backButton} alt="back" />
			</button>
		</div>
	);
};

export default ShopHeader;
