import axios from "../utils/axios";

export const createEnergyInvoice = async ({
  telegram_id,
  energy_reward,
  amount_stars,
}) => {
  const { data } = await axios.post(
    "/payments/create_energy_invoice",
    {},
    {
      params: {
        telegram_id,
        energy_reward,
        amount_stars,
      },
    }
  );

  return data;
};

export const createCogaPointsInvoice = async ({
  telegram_id,
  coga_points_reward,
  amount_stars,
}) => {
  const { data } = await axios.post(
    "/payments/create_coga_points_invoice",
    {},
    {
      params: {
        telegram_id,
        coga_points_reward,
        amount_stars,
      },
    }
  );

  return data;
};