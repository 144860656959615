import axios from "../utils/axios";

export const createPlayer = async (telegram_id, referral_code) => {
	const { data } = await axios.post(`/players/create`, {
		telegram_id,
	});
	return data;
};

export const addCharacterToPlayer = async (telegram_id, name) => {
	const { data } = await axios.post(`/players/player_add_character`, {
		telegram_id,
		name,
	});
	return data;
};

export const updatePlayerClicks = async (telegram_id, clicks_count) => {
	const { data } = await axios.post(`/players/${telegram_id}/click`, {
		clicks_count,
	});
	return data;
};

export const getPlayer = async (telegram_id) => {
	const { data } = await axios.get(`/players/${telegram_id}`);
	return data;
};

export const getCharacterById = async (character_id) => {
	const { data } = await axios.get(`/characters/${character_id}`);
	return data;
};

export const checkSubscription = async (telegram_id) => {
	const { data } = await axios.get(
		`/players/check_if_subscribe/${telegram_id}`,
	);

	return data;
};

export const upgradePlayerBuffs = async (telegram_id, upgrade_type) => {
	if (!telegram_id) {
		return new Promise((res, rej) => {
			setTimeout(() => rej("No telegram Id"), 1000);
		});
	}
	await axios.post(`/players/${telegram_id}/upgrade/${upgrade_type}`);
};

export const getPlayerUpgrades = async (telegram_id) => {
	const { data } = await axios.get(`/players/upgrades/${telegram_id}/`);
	return data.upgrades;
};

export const convertSoftTokens = async (telegram_id) => {
	const { data } = await axios.post(
		`/players/${telegram_id}/convert_soft_tokens/`,
	);
	return data;
};

export const updateSocialSubscription = async (telegram_id, socialName) => {
	const { data } = await axios.put(
		`social_subscriptions/${telegram_id}/${socialName}/subscribe`,
	);
	return data;
};

export const getRewardForSubscription = async (telegram_id, socialName) => {
	const { data } = await axios.put(
		`social_subscriptions/${telegram_id}/${socialName}/reward`,
	);
	return data;
};

export const getSocialSubscriptions = async (telegram_id) => {
	const { data } = await axios.get(`social_subscriptions/${telegram_id}`);

	return data;
};

export const getPlayerInvitingStatus = async (telegram_id) => {
	const { data } = await axios.get(`players/${telegram_id}/inviting_status`);
	return data;
};

export const notifyUserInactive = async (telegram_id) => {
	try {
		console.log("User status updated to inactive");
	} catch (error) {
		console.error("Failed to notify user inactivity:", error);
	}
};

export const getPlayerLevel = async (telegram_id) => {
	const { data } = await axios.get(`player/${telegram_id}/level`);
	return data;
};

export const sendNickname = async (telegram_id, nickname) => {
	await axios.put(
		`players/${telegram_id}/update_nick_name/?nick_name=${nickname}`,
	);
};

//Top 100 global
export const getTop100Players = async (telegram_id) => {
	const { data } = await axios.get(
		`players/leaderboard/top100?telegram_id=${telegram_id}`,
	);
	return data;
};

//Top 100 local
export const getTop100PlayersByTelegramId = async (telegram_id) => {
	const { data } = await axios.get(
		`players/leaderboard/top100/country?telegram_id=${telegram_id}`,
	);
	return data;
};

// Mock data
// getLocalUserPosition
export const getLocalUserPosition = async (useName) => {
	// const { data } = await axios.get(`players/leaderboard/top100/country?telegram_id=${telegram_id}`)
	const data = await new Promise((resolve) =>
		setTimeout(
			() =>
				resolve({
					nick_name: useName,
					character: "pet4",
					total_clicks: null,
					rank: null,
				}),
			500,
		),
	);
	return data;
};

// getGlobalUserPosition
export const getGlobalUserPosition = async (useName) => {
	const data = await new Promise((resolve) =>
		setTimeout(
			() =>
				resolve({
					nick_name: useName,
					character: "pet4",
					total_clicks: null,
					rank: null,
				}),
			500,
		),
	);
	// const { data } = await axios.get(`players/leaderboard/top100/country?telegram_id=${telegram_id}`)

	return data;
};

// send tgId when tutorial Loaded
export const sendTgId = async (telegram_id) => {
	const { data } = await axios.post(
		`statistics/bcoin2048_player_start_tutorial/?telegram_id=${telegram_id}`,
	);
	return data;
};

export const sendTgPremiumStatus = async (
	telegram_id,
	premiumStatus = false,
) => {
	await axios.put(
		`players/update_premium?telegram_id=${telegram_id}&is_premium=${premiumStatus}`,
	);
};
