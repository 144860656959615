import { FaStar } from 'react-icons/fa'
import { FaSquare } from 'react-icons/fa'
import { FaCircle } from 'react-icons/fa'
import { TbRectangleFilled } from 'react-icons/tb'

export const getRandomShapes = isTurboMode => {
    const shapes = [
        FaStar,
        FaCircle,
        FaSquare,
        TbRectangleFilled,
        FaStar,
        FaCircle,
        FaSquare,
        TbRectangleFilled,
        FaStar,
        FaCircle,
    ]

    if (isTurboMode) {
        return [...shapes, ...shapes]
    }

    return shapes
}
