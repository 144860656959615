import { useState, useEffect, useCallback } from 'react'

import Robot from 'assets/robot.webp'
import clsx from 'clsx'

import './Tutorial.css'
import tutorialStepsText from 'constants/TutorialText'
import TypingText from './TypingText/TypingText'
import PetSelectionStep from './PetSelectionStep/PetSelectionStep'
import PetTapStep from './PetTapStep/PetTapStep'
import { sounds } from 'utils'
import FormStep from './FormStep/FormStep'
import Start from 'components/Start/Start'
import PlayStep from './PlayStep/PlayStep'
import { addCharacterToPlayer } from 'api/playersApi'
import { sendTgId } from 'api/playersApi'

const Tutorial = ({ isSubscribed, selectCharacter, userId }) => {
    const [isTutorVisible, setIsTutorVisible] = useState(true)
    const [isFadingOut, setIsFadingOut] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [step, setStep] = useState(() => {
        const savedStep = localStorage.getItem('tutorialStep')
        const initialStep = savedStep && isSubscribed ? parseInt(savedStep, 10) : null

        if (initialStep !== null) {
            return initialStep
        } else if (isSubscribed) {
            return 2
        } else {
            return 1
        }
    })

    useEffect(() => {
        if (step === 2) {
            const sendTelegramId = async () => {
                try {
                    await sendTgId(userId)
                } catch (error) {
                    console.error('Error sending Telegram ID', error)
                }
            }

            sendTelegramId()
        }

        if (step === 7) {
            const timeout = setTimeout(() => {
                setIsFadingOut(true)
            }, 2500)

            return () => clearTimeout(timeout)
        } else {
            setIsFadingOut(false)
        }
    }, [step])

    const [selectedCharacter, setSelectedCharacter] = useState(() => {
        return localStorage.getItem('selectedCharacter') || ''
    })

    useEffect(() => {
        localStorage.setItem('tutorialStep', step)
    }, [step])

    useEffect(() => {
        localStorage.setItem('selectedCharacter', selectedCharacter)
    }, [selectedCharacter])

    const toggleTutorVisible = useCallback(() => {
        setIsTutorVisible(prev => !prev)
    }, [])

    const handleNextStep = useCallback(
        async (nextStep = null) => {
            sounds.approve.play()

            if (nextStep) {
                setStep(nextStep)
                return
            }
            if (step < 23) {
                setStep(prevStep => {
                    return prevStep + 1
                })
                return
            }

            setLoading(true)

            try {
                await addCharacterToPlayer(userId, selectedCharacter)
                selectCharacter(selectedCharacter)
                sessionStorage.clear()
                localStorage.clear()
            } catch (error) {
                setError('Failed to send nickname. Please try again.')
            } finally {
                setLoading(false)
            }
        },
        [step],
    )

    const handleCharacterSelection = useCallback(
        nameCharacter => {
            sounds.select.play()
            setSelectedCharacter(nameCharacter)
            handleNextStep(8)
        },
        [handleNextStep],
    )

    return (
        <div className="tutorial_container">
            {step === 1 && <Start tutorial={true} isSubscribed={isSubscribed} handleNextStep={handleNextStep} step={step} />}
            {step === 2 && <Start tutorial={true} isSubscribed={isSubscribed} handleNextStep={handleNextStep} />}
            {step === 3 && (
                <FormStep
                    /* selectCharacter={selectCharacter} */ userId={userId}
                    selectedCharacter={selectedCharacter}
                    handleNextStep={handleNextStep}
                />
            )}
            {step === 4 && <PlayStep handleNextStep={handleNextStep} />}
            {(step === 5 || step === 6 || step === 7) && (
                <PetSelectionStep
                    handleNextStep={handleNextStep}
                    handleSelectClick={handleCharacterSelection}
                    step={step}
                    toggleTutorVisible={toggleTutorVisible}
                />
            )}
            {step >= 8 && <PetTapStep selectedCharacter={selectedCharacter} step={step} handleNextStep={handleNextStep} userId={userId} />}

            {/* ===================== */}
            {/* STEP 1 - SUBSCRIBE - was STEP 1  - slide - done */}
            {/* STEP 2 - START - was STEP 2 - slide 2 - done */}
            {/* STEP 3 - FORM NICKNAME - was STEP 18  - slide 4 -done */}
            {/* STEP 4 - PLAY BUTTON - new!!!!!  - slide 5 - done */}
            {/* STEP 5 - SELECT PET - was STEP 3  - slide 6  - done */}
            {/* STEP 5 - when info menu opened, robot and text hidden - done */}
            {/* STEP 6 - ability to SELECT PET - was STEP 4  - slide 8 - done */}
            {/* STEP 7 - ability to SELECT PET without robot - new!!!!  - slide 9 - done*/}
            {/* STEP 8 - TAP PET with hand - was STEP 5  - slide 10 - done */}
            {/* STEP 9 - TAP PET without hand - new!!!!  - slide 11 - done */}
            {/* STEP 10 - energy for pets tap- was STEP 7  - slide 12  - done*/}
            {/* STEP 11 - coga coins- was STEP 8  - slide 13 - done */}
            {/* STEP 12 - about storage info - part 1 - was STEP 9  - slide 14 - done  */}
            {/* STEP 13 - about storage info - part 2 -  was STEP 10  - slide 15 - done*/}
            {/* STEP 14 - about storage info - part 3 -  was STEP 11  - slide  16 - done*/}
            {/* STEP 15 - lvl info -  was STEP 12 - slide 17 - done */}
            {/* STEP 16 - lvl info menu -  was STEP 13 - slide 18 - done */}
            {/* STEP 17 - lvl info menu without robot and backdrop -  new!!!! - slide 19 - done*/}
            {/* STEP 18 - hand for infoMenu -  was STEP 14 - slide 20 - done*/}
            {/* STEP 19 - opened infoMenu -  was STEP 15 - block green button!!!!- slide 21 - done */}
            {/* STEP 20 - opened infoMenu without robot and backdrop -  new!!!! - activate green button!!!!- slide 22 - done */}
            {/* STEP 21 - hand for achieveMenu -  was STEP 16 - slide 23 - done */}
            {/* STEP 22 - opened achieveMenu -  was STEP 17 - slide 24 - done */}
            {/* STEP 23 - opened achieveMenu without robot and backdrop -  new!!!! - slide 25 */}

            {isTutorVisible && (
                <div
                    className={clsx(
                        'tutorial_robot_text_wrapper',
                        {
                            no_visible: [1, 2, 4, 9, 18, 20, 21].includes(step),
                        },
                        step === 7 && isFadingOut && 'tutorial_robot_text_wrapper--fade-out',

                        { 'tutorial_robot_text_wrapper--small': step === 8 || step === 16 },
                        { 'tutorial_robot_text_wrapper--very-raised': step === 19 },
                        { 'tutorial_robot_text_wrapper--big': step === 9 || step === 10 || step === 11 },
                    )}
                >
                    <div className="tutorial_img_wrapper">
                        <img
                            src={Robot}
                            alt="Robot"
                            width={200}
                            className={clsx('tutorial_robot_img', { 'tutorial_robot_img--hidden': step === 17 || step === 23 })}
                        />
                    </div>
                    <div className={clsx('btn_text_container', { button_visible: step === 5 })}>
                        <div className={clsx('tutorial_text_wrapper', {})}>
                            <TypingText text={tutorialStepsText[`step${step}`]} typingSpeed={30} step={step} />
                        </div>
                        <div className={clsx('tutorial_button_wrapper')}>
                            <button
                                type="button"
                                onClick={() => handleNextStep()}
                                className={clsx('btn_try', { hidden: [1, 2, 3, 5, 6, 7, 8, 18].includes(step) })}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Tutorial
