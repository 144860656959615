import React, { useState, useEffect, memo } from 'react'
import './TypingText.css'
import clsx from 'clsx'

const TypingText = ({ text = '', step }) => {
    const renderedText = text.split('\n').map((line, idx) => (
        <React.Fragment key={idx}>
            {line}
            <br />
        </React.Fragment>
    ))

    return (
        <div>
            <p
                className={clsx(
                    'tutorial_text',
                    // { small: step === 8 || step === 15 || step === 17 },
                    { very_small: step === 12 || step === 13 || step === 14 },
                    { not_visible: step === 17 || step === 23 },
                )}
            >
                {renderedText}
            </p>
        </div>
    )
}

export default memo(TypingText)
