export const startInterval = (callback, interval) => {
    return setInterval(callback, interval)
}

export const stopInterval = intervalRef => {
    if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
    }
}
