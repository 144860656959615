import './IconBackground.css'

const IconBackground = ({ children, level = 1 }) => {
    return (
        <div className="background">
            {children}
            <div className="level_wrapper">
                <p className="level_wrapper_text"> {level} LVL</p>
            </div>
        </div>
    )
}

export default IconBackground
