export const formatValue = value => {
    if (value >= 1_000_000_000_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000_000_000_000).toFixed(3) + 'Dc'
    } else if (value >= 1_000_000_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000_000_000).toFixed(3) + 'N'
    } else if (value >= 1_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000).toFixed(3) + 'Oc'
    } else if (value >= 1_000_000_000_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000_000_000_000).toFixed(3) + 'Sp'
    } else if (value >= 1_000_000_000_000_000) {
        return (value / 1_000_000_000_000_000).toFixed(3) + 'Qi'
    } else if (value >= 1_000_000_000_000) {
        return (value / 1_000_000_000_000).toFixed(3) + 'B'
    } else if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(3) + 'M'
    } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(3) + 'K'
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(3)
    }
    return Math.floor(value).toLocaleString('de-DE')
}
