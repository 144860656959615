import React, { useEffect, useState } from 'react'
import './PetSelectionStep.css'
import CharacterSelection from '../../CharacterSelection/CharacterSelection'
import clsx from 'clsx'

const PetSelectionStep = ({ /* toggleShowTutor, */ /* toggleShowTutorText,  */ step, handleNextStep, ...props }) => {
    const [showBackdrop, setShowBackdrop] = useState(true)

    const toggleShowBackdrop = () => {
        if (showBackdrop) {
            setShowBackdrop(false)
            // toggleShowTutorText()
        }
        return
    }

    useEffect(() => {
        if (step === 6 || step === 7) setShowBackdrop(false)
    }, [])

    return (
        <div className="tuturial_pet_selection_container">
            <div className={clsx('pet_selection_backdrop', { no_visible: !showBackdrop })} />
            <CharacterSelection toggleShowBackdrop={toggleShowBackdrop} step={step} handleNextStep={handleNextStep} {...props} />
        </div>
    )
}

export default PetSelectionStep
