import { createContext, useContext, useMemo, useState } from "react";
import { calculateTimeToNextTick } from "utils";

export const EnergyTimerContext = createContext(null);

export const EnergyTimerProvider = ({ children }) => {
	const [nextUpdateTime, setNextUpdateTime] = useState(0);
	const [totalTimeLeft, setTotalTimeLeft] = useState(() => calculateTimeToNextTick(nextUpdateTime));

	const value = useMemo(
		() => ({
			nextUpdateTime,
			setNextUpdateTime,
			totalTimeLeft,
			setTotalTimeLeft,
		}),
		[nextUpdateTime, totalTimeLeft],
	);

	return (
		<EnergyTimerContext.Provider value={value}>
			{children}
		</EnergyTimerContext.Provider>
	);
};

export const useEnergyTimer = () => {
	const context = useContext(EnergyTimerContext);

	if (!context) {
		throw new Error("useEnergyTimer must be used within a EnergyTimerProvider");
	}

	return context;
};
